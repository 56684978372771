import React from 'react';
import { detect } from 'detect-browser';

interface ExtensionSuggestionProps {
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void;
}


export const ExtensionSuggestion = ({ isOpen, setOpen }: ExtensionSuggestionProps) => {
    const result = detect();
    const chrome = {
        href: 'https://chrome.google.com/webstore/detail/hype-map/djkaengmimflaaohjpenbagdbagndale',
        label: 'Get on chrome store'
    };

    const firefox = {
        href: 'https://addons.mozilla.org/en-US/firefox/addon/hype-map/',
        label: 'Get on firefox store',
    }
    const labels = {
        primary: chrome,
        secondary: firefox
    };
    if (result && result.name === 'firefox') {
      labels.primary = firefox;  
      labels.secondary = chrome;  
    }
    return (
    <div className="relative">
        <div className="w-full sm:w-auto inline-flex items-center justify-center border border-transparent rounded-md bg-white shadow-sm">
            <a
                href={labels.primary.href}
                className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 text-base leading-6 font-semibold text-gray-900 hover:text-gray-600 focus:outline-none focus:text-gray-600 transition ease-in-out duration-150 xl:text-lg xl:py-4"
            >
                {labels.primary.label}
            </a>
            <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-600">

                <button onClick={() => setOpen(!isOpen)} type="button" className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                    <svg version="1.1" className="fill-current h-4 w-4" viewBox="0 0 20 20">
                        <path d="M17.418,6.109c0.272-0.268,0.709-0.268,0.979,0s0.271,0.701,0,0.969l-7.908,7.83 c-0.27,0.268-0.707,0.268-0.979,0l-7.908-7.83c-0.27-0.268-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.979,0L10,13.25 L17.418,6.109z"></path>
                    </svg>

                </button>
            </div>

        </div>
        {isOpen &&
            <div className="origin-top-right absolute right-0 mt-1 w-full rounded-md shadow-lg">
                <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                    <a href={labels.secondary.href} className="block  px-6 py-3 text-base leading-6 font-semibold text-gray-900 hover:text-gray-600 focus:outline-none focus:text-gray-600 transition ease-in-out duration-150" role="menuitem">{labels.secondary.label}</a>
                </div>
            </div>
        }
    </div>
)}