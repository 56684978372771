import React from 'react';

export const PrivacyPolicy = () => (
    <div className="bg-black-rock min-h-screen h-full">
        <nav className="bg-black-rock">
            <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    <div className="flex-1 flex items-center justify-center">
                        <div className="flex-shrink-0">
                            <a href="/"><img className="block lg:hidden h-8 w-auto" src="/logo.png" alt="Workflow logo" /></a>
                            <a href="/"><img className="hidden lg:block h-8 w-auto" src="/logo.png" alt="Workflow logo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <main>
            <div className="px-6 container mx-auto">
                <div className="termly"></div>
                <h1 className="max-w-4xl lg:mx-auto mt-2 text-3xl leading-8 font-extrabold tracking-tight text-brand sm:text-4xl sm:leading-10">
                    PRIVACY NOTICE
                </h1>
                <p className="mt-4 max-w-4xl text-xl leading-7 text-gray-200 lg:mx-auto">
                    Last updated July 30, 2020
                </p>
                <br />
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    Thank you for choosing to be part of our community at HypeMap (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at contact@hypemap.io.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    When you visit our website <a href="http://hypemap.io">http://hypemap.io</a> (the "Website"), and more generally, use any of our services (the "Services", which include the Website), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    This privacy notice applies to all information collected through our Services (which, as described above, includes our Website), as well as any related services, sales, marketing or events.
                </p>
                <p className="mt-4 font-bold max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
                </p>
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    1. WHAT INFORMATION DO WE COLLECT?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    We do not collect any user information.
                </p>
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span> We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    We may process or share your data that we hold based on the following legal basis:
                </p>
                <div className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <ul className="ml-10 list-disc">
                        <li><span className="font-bold">Consent:</span> We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
                        <li><span className="font-bold">Legitimate Interests:</span> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                        <li><span className="font-bold">Performance of a Contract:</span> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
                        <li><span className="font-bold">Legal Obligations:</span> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                        <li><span className="font-bold">Vital Interests:</span> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                    </ul>
                </div>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    More specifically, we may need to process your data or share your personal information in the following situations:
                </p>
                <div className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <ul className="ml-10 list-disc">
                        <li><span className="font-bold">Business Transfers.</span>  We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    </ul>
                </div>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    3. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span>  We may transfer, store, and process your information in countries other than your own.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    Our servers are located in USA. If you are accessing our Website from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    4. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 90 days.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    5. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span> We aim to protect your personal information through a system of organizational and technical security measures.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    6. WHAT ARE YOUR PRIVACY RIGHTS?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span> You may review, change, or terminate your account at any time.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a className="underline" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    If you are resident in Switzerland, the contact details for the data protection authorities are available here: <a className="underline" href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    7. CONTROLS FOR DO-NOT-TRACK FEATURES
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span> In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    If you are under 18 years of age, reside in California, and have a registered account with the Website, you have the right to request removal of unwanted data that you publicly post on the Website. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).  
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    9. DO WE MAKE UPDATES TO THIS NOTICE?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    <span className="font-bold">In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                </p>
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    If you have questions or comments about this notice, you may email us at support@hypemap.io
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    HypeMap
                    <br />
                    Victoria 3003, Australia
                    <br />
                    support@hypemap.io
                </p>
                <br />
                <h2 className="max-w-4xl lg:mx-auto mt-2 text-2xl leading-8 font-extrabold tracking-tight text-brand sm:text-3xl sm:leading-10">
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </h2>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking <a className="underline" href="https://app.termly.io/notify/f083d96b-ae4f-48ca-bf3e-9e38059855c2">here</a>. We will respond to your request within 30 days.
                </p>
                <p className="mt-4 max-w-4xl text-base leading-7 text-gray-200 lg:mx-auto">
                    These terms of use were created using <a href="https://termly.io/products/terms-and-conditions-generator/?ftseo" className="underline">Termly’s Terms and Conditions Generator.</a>
                </p>
            </div>
        </main>
    </div>
);