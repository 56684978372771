import React from 'react';
import './App.css';
import './tailwind.output.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes/Routes';

function App() {

  return (
    <BrowserRouter children={Routes} basename={"/"} />
  );
}

export default App;
