import React, { useState } from 'react';
import { ExtensionSuggestion } from '../components/GetExtensionLink';

export const PublicPage = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="relative min-h-screen overflow-hidden bg-black-rock lg:bg-gray-300">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2" >
                <img alt="" className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="/hero.jpeg" />
            </div>
            <div className="relative min-h-screen lg:min-w-3xl xl:min-w-4xl lg:flex lg:items-center lg:justify-center lg:w-3/5 lg:py-20 lg:pl-8 lg:pr-8 bg-no-repeat" style={{ backgroundImage: "url('/background.svg')", backgroundSize: '100% auto', backgroundPosition: '-5px -5px', }}>
                <div>
                    <div className="px-6 pt-8 pb-12 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none lg:pt-0 lg:pb-16">
                        <div className="flex items-center justify-between">
                            <div>
                                <img className="h-6 lg:h-8 xl:h-9" src="/logo.png" alt="Hype map" />
                            </div>
                        </div>
                    </div>
                    <div className="px-6 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none">
                        <p className="text-sm font-semibold text-gray-300 uppercase tracking-wider">
                            Now on chrome store
            </p>
                        <h1 className="mt-3 text-3xl leading-9 font-semibold font-display text-white sm:mt-6 sm:text-4xl sm:leading-10 xl:text-5xl xl:leading-none">
                            See Twitch chat hype spots on VODs
              <br className="hidden sm:inline" />
                            <span className="lg:hidden"> </span>
                            <span className="text-teal-400">
                                filter to the spam you are looking for.
              </span>
                        </h1>
                        <p className="mt-2 text-lg leading-7 text-gray-300 sm:mt-3 sm:text-xl sm:max-w-xl xl:mt-4 xl:text-2xl xl:max-w-3xl">
                            View 5 VODs daily, need more twitch in your life? Go outside, take a break or do something else instead.
                        </p>
                        <div className="mt-6 sm:flex sm:mt-8 xl:mt-12">
                            <ExtensionSuggestion isOpen={isOpen} setOpen={setOpen} />
                            <a href={`https://www.patreon.com/zecuria`} className="mt-4 sm:ml-4 sm:mt-0 w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150 xl:text-lg xl:py-4">
                                Support development →
              </a>
                        </div>
                    </div>
                    <div className="mt-8 sm:mt-12 relative h-64 overflow-hidden bg-gray-300 lg:hidden">
                        <div className="absolute scroll-bg" style={{ height: '800%', width: '400%', backgroundSize: '400px auto', backgroundImage: "url('/hero.jpeg')", }}></div>
                    </div>
                    <div className="px-6 py-8 sm:pt-12 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-full lg:py-0 lg:pt-24">
                        <p className="text-sm font-semibold text-gray-300 uppercase tracking-wider">Developed by</p>
                        <div className="mt-4 sm:flex">
                            <a href="https://twitch.tv/zecuria" className="flex items-center no-underline">
                                <div className="flex-shrink-0">
                                    <img className="h-12 w-12 rounded-full border-2 border-white" src="/profile.jpeg" alt="" />
                                </div>
                                <div className="ml-3">
                                    <p className="font-semibold text-white leading-tight">zecuria</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
