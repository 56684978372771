import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { PublicPage } from "../Pages/PublicPage";
import { TermsAndConditions } from "../Pages/TermsAndConditions";
import { PrivacyPolicy } from "../Pages/PrivacyPolicy";

export const Routes = (
    <Switch>
        <Route exact path="/terms" component={TermsAndConditions} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/" component={PublicPage} />
    </Switch>
);
